import { Combobox, Transition } from '@headlessui/react'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useMemo, useState } from 'react'
import {
	FieldErrors,
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch,
	useForm
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import cond from 'cond-construct'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Checkbox } from 'components/inputs/checkbox'
import { Input } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { AppointmentStatus } from 'constants/constants'
import { useAppSelector } from 'hooks'
import appointmentService from 'services/appointment-service'
import destinationTypeService from 'services/category-service'
import companyService from 'services/company-service'
import destinationService from 'services/destination-service'
import userService from 'services/user-service'
import { appendDateTime } from 'utils/date'
import { getAppLang, getTKey } from 'utils/language'

enum FormSteps {
	APPOINTMENTINFO,
	APPOINTMENTQUESTIONS,
	CONFIRMAPPOINTMENT
}

interface CreateAppointmentProps {
	showInfo?: boolean
	className?: string
	createdClient?: string
	onAddClient?: () => void
	onConfirm: () => void
	onCancel: () => void
}

export const CreateAppointment = ({
	showInfo = true,
	createdClient,
	onAddClient,
	className,
	onConfirm,
	onCancel
}: CreateAppointmentProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('appointments.create')

	const auth = useAppSelector(state => state.auth)

	const [steps, setSteps] = useState(FormSteps.APPOINTMENTINFO)
	const [isLoading, setIsLoading] = useState(false)
	const [company, setCompany] = useState<Company>()
	const [companyDrivers, setCompanyDrivers] = useState<Driver[]>([])
	const [companyDestinations, setCompanyDestinations] = useState<Destination[]>([])
	const [destinationTypes, setDestinationTypes] = useState<DestinationType[]>([])
	const [additionalInfo, setAdditionalInfo] = useState<string>('')
	const [additionalCost, setAdditionalCost] = useState<string>('')

	const schema = yup.object<AppointmentForm>().shape({
		id_client: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema => schema.required(t(tKey('errors.client')))
		}),
		appointment_date: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema =>
				schema
					.required(t(tKey('errors.appointmentDate')))
					.test('appointment-date', t(tKey('errors.appointmentDateInPast')), function (value) {
						const appointmentDate = DateTime.fromISO(value)
						const today = DateTime.now().startOf('day')
						return appointmentDate >= today
					})
		}),
		appointment_time: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema =>
				schema
					.required(t(tKey('errors.appointmentTime')))
					.test('appointment-time', t(tKey('errors.appointmentTimeInPast')), function (value) {
						const { appointment_date } = this.parent
						const appointmentDate = DateTime.fromISO(appointment_date)
						const appointmentTime = DateTime.fromFormat(value, 'HH:mm')
						const now = DateTime.now()

						if (appointmentDate.hasSame(now, 'day')) {
							return appointmentTime >= now
						}
						return true
					})
		}),
		pickup_time: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema =>
				schema
					.required(t(tKey('errors.pickupTime')))
					.test('pickup-time', t(tKey('errors.pickupTimeInPast')), function (value) {
						const { appointment_date } = this.parent
						const appointmentDate = DateTime.fromISO(appointment_date)
						const pickupTime = DateTime.fromFormat(value, 'HH:mm')
						const now = DateTime.now()

						if (appointmentDate.hasSame(now, 'day')) {
							return pickupTime >= now
						}
						return true
					})
		}),
		pickup_address: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema => schema.required(t(tKey('errors.pickupAddress')))
		}),
		one_way: yup.boolean(),
		return_address: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema =>
				schema.test('return-address', t(tKey('errors.returnAddress')), function (value) {
					const one_way = this.parent.one_way
					if (!one_way) return true
					if (!value) return false
					return true
				})
		}),
		return_time: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema =>
				schema
					.test('return-time', t(tKey('errors.returnTime')), function (value) {
						const one_way = this.parent.one_way
						if (!one_way) return true
						if (!value) return false
						return true
					})
					.test('return-time', t(tKey('errors.returnTimeAfterPickup')), function (value) {
						const { pickup_time } = this.parent
						if (!pickup_time || !value) return true

						const returnTime = DateTime.fromFormat(value, 'HH:mm')
						const pickupTime = DateTime.fromFormat(pickup_time, 'HH:mm')

						return returnTime > pickupTime
					})
		}),
		id_destination: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema => schema.required(t(tKey('errors.destination')))
		})
	})

	const {
		register,
		watch,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors }
	} = useForm<AppointmentForm>({
		resolver: yupResolver(schema as any),
		context: { step: steps },
		mode: 'all'
	})

	const selectedDestination = watch('id_destination')

	useEffect(() => {
		companyService.getCompanyById(auth.companyId).then(res => setCompany(res))
		if (auth.is_adminUser === true) {
			destinationService.getActiveDestinations().then(res => setCompanyDestinations(res))
			destinationTypeService.getDestinationTypes().then(res => setDestinationTypes(res))
		}
	}, [auth])

	const nextStep = handleSubmit(data => {
		setSteps(prev => prev + 1)
	})

	const prevStep = () => {
		steps === FormSteps.APPOINTMENTINFO ? onCancel() : setSteps(prev => prev - 1)
	}

	const onSubmit = handleSubmit((data: any) => {
		setIsLoading(true)

		const selectedDestination = companyDestinations.find(
			destination => destination._id === data.id_destination
		) as Destination

		const payload: AppointmentForm = {
			company_name: company?.company_name as string,
			appointment_time: appendDateTime(data.appointment_date, data.appointment_time),
			pickup_time: appendDateTime(data.appointment_date, data.pickup_time),
			id_destination_type: destinationTypes.find(
				type => type._id === selectedDestination.type
			) as DestinationType,
			id_client: data.id_client,
			duration: data.duration,
			one_way: !data.one_way,
			pickup_address: data.pickup_address,
			return_address: data.return_address ?? null,
			return_time: data.one_way ? appendDateTime(data.appointment_date, data.return_time) : null,
			id_destination: data.id_destination,
			destination: selectedDestination,
			id_company: auth.companyId,
			total_price:
				(companyDestinations.find(destination => destination._id === data.id_destination)?.cost ??
					0) + (Number(additionalCost) ?? 0),
			remarks: additionalInfo,
			status: AppointmentStatus.PENDENT,
			created: DateTime.now().toMillis()
		}

		appointmentService
			.createAppointment(payload)
			.then(res => {
				toast.success(t(tKey('toast.appointmentSuccess')))
				onConfirm()
			})
			.catch(err => toast.error(err?.response?.data?.message ?? t(tKey('toast.appointmentError'))))
			.finally(() => setIsLoading(false))
	})

	const renderComponent = cond([
		[
			steps === FormSteps.APPOINTMENTINFO,
			() => (
				<AppointmentInfoForm
					{...{
						errors,
						register,
						watch,
						showInfo,
						createdClient,
						setValue,
						onAddClient,
						destinations: companyDestinations,
						getValues,
						selectedDestination
					}}
				/>
			)
		],
		[
			steps === FormSteps.APPOINTMENTQUESTIONS,
			() => (
				<AppointmentQuestionForm
					{...{
						errors,
						register,
						watch,
						showInfo,
						createdClient,
						drivers: companyDrivers,
						destinations: companyDestinations,
						additionalInfo,
						setAdditionalInfo,
						additionalCost,
						setAdditionalCost
					}}
				/>
			)
		]
	])

	return (
		<form
			className={clsx('max-md:px-5 md:px-8 py-6', className)}
			onSubmit={event => {
				if (steps === FormSteps.APPOINTMENTQUESTIONS) {
					onSubmit(event)
				} else {
					nextStep(event)
				}
			}}>
			<>{renderComponent}</>
			<div className="flex justify-end gap-x-6 py-6">
				<button className="max-md:text-sm" type="button" onClick={prevStep}>
					{steps === FormSteps.APPOINTMENTINFO
						? t(tKey('buttons.cancel'))
						: t(tKey('buttons.back'))}
				</button>
				<Button
					disabled={isLoading}
					className="px-8 py-3 max-md:text-sm max-md:py-[9px]"
					type="submit">
					{isLoading ? (
						<div className="flex items-center justify-center gap-x-5">
							<Spinner />
							<span className="animate-pulse whitespace-nowrap">
								{t(tKey('buttons.pleaseWait'))}
							</span>
						</div>
					) : steps === FormSteps.APPOINTMENTQUESTIONS ? (
						t(tKey('buttons.makeAppointment'))
					) : (
						t(tKey('buttons.next'))
					)}
				</Button>
			</div>
		</form>
	)
}

interface FormProps {
	register: UseFormRegister<AppointmentForm>
	errors: FieldErrors<any>
	setValue?: UseFormSetValue<AppointmentForm>
	getValues?: UseFormGetValues<AppointmentForm>
	watch?: UseFormWatch<any>
}

interface AppointmentFormProps extends FormProps {
	onAddClient?: () => void
	createdClient?: string
	destinations: Destination[]
	selectedDestination: string
	showInfo: boolean
}

const AppointmentInfoForm = ({
	register,
	errors,
	watch,
	onAddClient,
	createdClient,
	setValue,
	showInfo = true,
	getValues,
	destinations,
	selectedDestination
}: AppointmentFormProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('appointments.create')

	const oneWay = watch?.('one_way')

	const [query, setQuery] = useState('')
	const [destinationQuery, setDestinationQuery] = useState('')
	const [users, setUsers] = useState<User[]>([])
	const [forceRefresh, setForceRefresh] = useState(false)

	useEffect(() => {
		userService.getCompanyClients().then(res => {
			setUsers(res)
			if (createdClient) {
				setValue?.('id_client', createdClient, { shouldValidate: true })
			}
		})
	}, [forceRefresh])

	const filteredOptions = useMemo(() => {
		if (users) {
			return users.filter(user => {
				const fname = user.fname ?? ''
				const lname = user.lname ?? ''
				const name = fname + ' ' + lname
				return (
					query === '' ||
					name
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, '')) ||
					DateTime.fromMillis(Number(user.dob))
						.toFormat('dd.LL.yyyy')
						.toLowerCase()
						.includes(query.toLowerCase()) ||
					user.city
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, '')) ||
					user.zipcode
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, ''))
				)
			})
		} else {
			return users
		}
	}, [users, query])

	const filteredDestinations = useMemo(() => {
		if (destinations) {
			return destinations.filter(destination => {
				return (
					destinationQuery === '' ||
					destination.destination_name
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(destinationQuery.toLowerCase().replace(/\s+/g, '')) ||
					destination.cost
						.toString()
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(destinationQuery.toLowerCase().replace(/\s+/g, '')) ||
					destination.distance
						.toString()
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(destinationQuery.toLowerCase().replace(/\s+/g, '')) ||
					destination.city
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(destinationQuery.toLowerCase().replace(/\s+/g, '')) ||
					destination.zip_code
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(destinationQuery.toLowerCase().replace(/\s+/g, ''))
				)
			})
		} else {
			return destinations
		}
	}, [destinations, destinationQuery])

	return (
		<div className="flex min-h-[70vh] flex-col gap-y-[18px]">
			<div className="flex justify-between items-start">
				<h3 className="text-primary max-md:text-xs">{t(tKey('titles.requiredDetails'))}</h3>
			</div>

			<Combobox
				value={getValues?.('id_client')}
				onChange={(value: string) => {
					const selectedClient = users.find(user => user._id === value)
					setValue?.('id_client', value, { shouldValidate: true })
					setValue?.(
						'pickup_address',
						`${selectedClient?.address ?? ''} ${selectedClient?.city ?? ''} ${
							selectedClient?.zipcode ?? ''
						}`,
						{ shouldValidate: true }
					)
					setValue?.(
						'return_address',
						`${selectedClient?.address ?? ''} ${selectedClient?.city ?? ''} ${
							selectedClient?.zipcode ?? ''
						}`,
						{ shouldValidate: true }
					)
				}}>
				<div className="relative mt-1">
					<div className="relative cursor-default w-full overflow-hidden bg-white text-left border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
						<Combobox.Input
							placeholder={t(tKey('placeholders.client'))}
							className="border border-border pr-7 w-full py-2 md:py-3 rounded focus:border-secondary text-sm leading-5 text-gray-900 focus:ring-0"
							displayValue={option =>
								option
									? users.find(user => user._id === getValues?.('id_client'))?.fname +
										' ' +
										users.find(user => user._id === getValues?.('id_client'))?.lname
									: ''
							}
							onChange={event => setQuery(event.target.value)}
						/>
						{errors.id_client && (
							<p className="text-red-500 mt-1 text-xs">{errors.id_client.message as string}</p>
						)}
						<Combobox.Button
							className={clsx('absolute inset-y-0 flex items-center right-0 pr-2', {
								'-translate-y-2': errors.id_client
							})}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
									fill="#123258"
								/>
							</svg>
						</Combobox.Button>
					</div>
					<Transition
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<Combobox.Options className="absolute mt-1 z-50 max-h-60 min-w-max w-full overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
							{Array.isArray(users) &&
								filteredOptions.map((option, index) => {
									const fname = option.fname ?? ''
									const lname = option.lname ?? ''
									return (
										<Combobox.Option
											key={index}
											className={({ active }) =>
												`relative cursor-default select-none py-0.5 ${
													active ? 'bg-[#13BAB40F] text-white' : 'text-gray-900'
												}`
											}
											value={option._id}>
											{({ selected, active }) => (
												<span className="flex gap-x-3 px-4 md:px-6 py-1.5 items-center">
													<span className="flex-col group items-start text-primary font-semibold w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left rounded-md">
														{fname + ' ' + lname}

														<span className="text-tertiary text-xs">
															{option.dob &&
																DateTime.fromMillis(Number(option.dob))
																	.setLocale(getAppLang())
																	.toFormat('dd.LL.yyyy')}
															{option.city && option.zipcode
																? ` - ${option.city} - ${option.zipcode}`
																: ''}
														</span>
													</span>
												</span>
											)}
										</Combobox.Option>
									)
								})}

							{!showInfo && (
								<div
									onClick={onAddClient}
									className="flex cursor-pointer sticky bottom-0 w-full bg-white items-center px-3 justify-end gap-2 py-3.5">
									<PlusCircleIcon className="h-3.5 w-3.5 text-primary-light" />
									<p className="text-sm text-primary-light">
										{t('calendar.createEvent.labels.addNewClient')}
									</p>
								</div>
							)}
						</Combobox.Options>
					</Transition>
				</div>
			</Combobox>

			<Combobox
				value={getValues?.('id_destination')}
				onChange={(value: string) => setValue?.('id_destination', value, { shouldValidate: true })}>
				<div className="relative mt-1">
					<div className="relative cursor-default w-full overflow-hidden bg-white text-left border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
						<Combobox.Input
							placeholder={t(tKey('placeholders.destination'))}
							className="border border-border pr-7 w-full py-2 md:py-3 rounded focus:border-secondary text-sm leading-5 text-gray-900 focus:ring-0"
							displayValue={option =>
								option
									? (destinations.find(
											destination => destination._id === getValues?.('id_destination')
										)?.destination_name as string)
									: ''
							}
							onChange={event => setDestinationQuery(event.target.value)}
						/>
						{errors.id_destination && (
							<p className="text-red-500 mt-1 text-xs">{errors.id_destination.message as string}</p>
						)}
						<Combobox.Button
							className={clsx('absolute inset-y-0 flex items-center right-0 pr-2', {
								'-translate-y-2': errors.id_destination
							})}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
									fill="#123258"
								/>
							</svg>
						</Combobox.Button>
					</div>
					<Transition
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<Combobox.Options className="absolute mt-1 z-50 max-h-60 min-w-max w-full overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
							{Array.isArray(destinations) &&
								filteredDestinations.map((option, index) => (
									<Combobox.Option
										key={index}
										className={({ active }) =>
											`relative cursor-default select-none py-0.5 ${
												active ? 'bg-[#13BAB40F] text-white' : 'text-gray-900'
											}`
										}
										value={option._id}>
										{({ selected, active }) => (
											<span className="flex gap-x-3 px-4 md:px-6 py-1.5 items-center">
												<span className="flex-col group items-start text-primary font-semibold w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left rounded-md">
													{option.destination_name}

													<span className="text-tertiary text-xs">
														{option.distance} km
														{` - CHF${option.cost?.toFixed(2)}`}
														{option.city && option.zip_code
															? ` - ${option.city} - ${option.zip_code}`
															: ''}
													</span>
												</span>
											</span>
										)}
									</Combobox.Option>
								))}
						</Combobox.Options>
					</Transition>
				</div>
			</Combobox>

			<Input
				name="appointment_date"
				type="date"
				register={register}
				errors={errors}
				labelText={t(tKey('labels.appointmentDate'))}
			/>
			<Input
				name="appointment_time"
				type="time"
				register={register}
				errors={errors}
				labelText={t(tKey('labels.appointmentTime'))}
			/>
			<Input
				name="pickup_time"
				type="time"
				register={register}
				errors={errors}
				labelText={t(tKey('labels.pickupTime'))}
			/>
			<Input
				name="pickup_address"
				register={register}
				errors={errors}
				labelText={t(tKey('labels.pickupAddress'))}
			/>
			<Select
				labelText={t('calendar.createEvent.labels.repeat')}
				name="duration"
				register={register}
				errors={errors}>
				<option value="none">{t('calendar.createEvent.labels.never')}</option>
				<option value="daily">{t('calendar.createEvent.labels.everyday')}</option>
				<option value="weekly">{t('calendar.createEvent.labels.everyWeek')}</option>
				<option value="monthly">{t('calendar.createEvent.labels.everyMonth')}</option>
				<option value="yearly">{t('calendar.createEvent.labels.everyYear')}</option>
			</Select>
			<div className="text-sm gap-x-1 flex items-center ">
				<Checkbox name="one_way" register={register} error={errors} />
				<label className="flex whitespace-nowrap font-medium text-tertiary justify-right">
					{t(tKey('labels.oneWay'))}
				</label>
			</div>
			{oneWay && (
				<>
					<Input
						name="return_time"
						type="time"
						register={register}
						errors={errors}
						labelText={t(tKey('labels.returnTime'))}
					/>
					<Input
						name="return_address"
						register={register}
						errors={errors}
						className="hidden"
						labelText={t(tKey('labels.returnAddress'))}
					/>
				</>
			)}
		</div>
	)
}

interface AppointmentQuestionFormProps extends FormProps {
	drivers: Driver[]
	destinations: Destination[]
	additionalInfo: string
	setAdditionalInfo: (value: string) => void
	additionalCost: string
	setAdditionalCost: (value: string) => void
}

const AppointmentQuestionForm = ({
	watch,
	drivers,
	destinations,
	additionalInfo,
	setAdditionalInfo,
	additionalCost,
	setAdditionalCost,
	showInfo = true
}: AppointmentQuestionFormProps & { showInfo?: boolean }) => {
	const { t } = useTranslation()

	const appLanguage = getAppLang()
	const tKey = getTKey('appointments.create')

	const clientId = watch?.('id_client')
	const destinationId = watch?.('id_destination')
	const appointmentDate = watch?.('appointment_date')
	const appointmentTime = watch?.('appointment_time')
	const pickupTime = watch?.('pickup_time')

	const [users, setUsers] = useState<User[]>([])

	useEffect(() => {
		userService.getCompanyClients().then(res => setUsers(res))
	}, [])

	const selectedClient = users.find(user => user._id === clientId)
	const selectedDestination = destinations.find(destination => destination._id === destinationId)

	return (
		<div className="flex flex-col gap-y-3.5 md:gap-y-[18px]">
			{showInfo && (
				<div className="flex max-md:flex-col px-6 py-3 max-md:gap-y-2 justify-around bg-[#D3E3F14D]">
					<div className="flex items-center gap-x-5">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="36"
							height="35"
							viewBox="0 0 36 35"
							fill="none">
							<g clipPath="url(#clip0_3123_4045)">
								<circle cx="18" cy="10.4412" r="6" fill="#7F9AB2" fillOpacity="0.5" />
								<ellipse cx="18" cy="30.9412" rx="14" ry="12.5" fill="#7F9AB2" fillOpacity="0.5" />
							</g>
							<defs>
								<clipPath id="clip0_3123_4045">
									<rect width="36" height="36" fill="white" transform="translate(0 0.441162)" />
								</clipPath>
							</defs>
						</svg>
						<div className="flex items-center gap-x-3">
							<p className="text-primary max-md:text-sm">
								{selectedClient?.fname + ' ' + selectedClient?.lname}
							</p>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="11"
								height="11"
								viewBox="0 0 11 11"
								fill="none"
								className="shrink-0 max-md:h-1 max-md:w-1">
								<circle cx="5.5" cy="5.94116" r="5" fill="#D9D9D9" />
							</svg>
							<p className="text-primary text-xs whitespace-nowrap md:text-sm font-semibold">
								{selectedDestination?.destination_name}
							</p>
						</div>
					</div>
					<div className="flex flex-col items-center">
						<h5 className="text-sm font-bold">{t(tKey('labels.appointmentTime'))}:</h5>

						<div className="flex items-center gap-x-2">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								className="shrink-0 max-md:h-[18px] max-md:w-[18px]">
								<path
									d="M14.8846 19.129C14.3 19.129 13.8013 18.9226 13.3885 18.5097C12.9756 18.0969 12.7692 17.5982 12.7692 17.0136C12.7692 16.429 12.9756 15.9303 13.3885 15.5174C13.8013 15.1046 14.3 14.8982 14.8846 14.8982C15.4692 14.8982 15.9679 15.1046 16.3808 15.5174C16.7936 15.9303 17 16.429 17 17.0136C17 17.5982 16.7936 18.0969 16.3808 18.5097C15.9679 18.9226 15.4692 19.129 14.8846 19.129ZM5.61538 22.129C5.15513 22.129 4.77083 21.9748 4.4625 21.6665C4.15417 21.3581 4 20.9738 4 20.5136V7.74434C4 7.28409 4.15417 6.8998 4.4625 6.59147C4.77083 6.28314 5.15513 6.12897 5.61538 6.12897H7.3846V3.89819H8.46152V6.12897H15.6154V3.89819H16.6154V6.12897H18.3846C18.8449 6.12897 19.2292 6.28314 19.5375 6.59147C19.8458 6.8998 20 7.28409 20 7.74434V20.5136C20 20.9738 19.8458 21.3581 19.5375 21.6665C19.2292 21.9748 18.8449 22.129 18.3846 22.129H5.61538ZM5.61538 21.129H18.3846C18.5385 21.129 18.6795 21.0649 18.8077 20.9367C18.9359 20.8085 19 20.6674 19 20.5136V11.7443H5V20.5136C5 20.6674 5.0641 20.8085 5.1923 20.9367C5.32052 21.0649 5.46154 21.129 5.61538 21.129ZM5 10.7443H19V7.74434C19 7.59051 18.9359 7.44949 18.8077 7.32127C18.6795 7.19307 18.5385 7.12897 18.3846 7.12897H5.61538C5.46154 7.12897 5.32052 7.19307 5.1923 7.32127C5.0641 7.44949 5 7.59051 5 7.74434V10.7443Z"
									fill="#123258"
								/>
							</svg>
							<p className="text-primary text-xs md:text-sm">
								{DateTime.fromISO(appointmentDate).setLocale(appLanguage).toFormat('LLL dd, cccc')}
							</p>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="21"
								viewBox="0 0 20 21"
								fill="none"
								className="shrink-0 max-md:h-[18px] max-md:w-[18px]">
								<path
									d="M9.99935 19.2738C14.6017 19.2738 18.3327 15.5429 18.3327 10.9405C18.3327 6.33814 14.6017 2.60718 9.99935 2.60718C5.39698 2.60718 1.66602 6.33814 1.66602 10.9405C1.66602 15.5429 5.39698 19.2738 9.99935 19.2738Z"
									stroke="#123258"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10 5.94116V10.9412L13.3333 12.6078"
									stroke="#123258"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<p className="text-primary text-xs md:text-sm">{appointmentTime}</p>
						</div>
					</div>
					<div className="flex flex-col items-center">
						<h5 className="text-sm font-bold">{t(tKey('labels.pickupTime'))}:</h5>
						<div className="flex items-center gap-x-2">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								className="shrink-0 max-md:h-[18px] max-md:w-[18px]">
								<path
									d="M14.8846 19.129C14.3 19.129 13.8013 18.9226 13.3885 18.5097C12.9756 18.0969 12.7692 17.5982 12.7692 17.0136C12.7692 16.429 12.9756 15.9303 13.3885 15.5174C13.8013 15.1046 14.3 14.8982 14.8846 14.8982C15.4692 14.8982 15.9679 15.1046 16.3808 15.5174C16.7936 15.9303 17 16.429 17 17.0136C17 17.5982 16.7936 18.0969 16.3808 18.5097C15.9679 18.9226 15.4692 19.129 14.8846 19.129ZM5.61538 22.129C5.15513 22.129 4.77083 21.9748 4.4625 21.6665C4.15417 21.3581 4 20.9738 4 20.5136V7.74434C4 7.28409 4.15417 6.8998 4.4625 6.59147C4.77083 6.28314 5.15513 6.12897 5.61538 6.12897H7.3846V3.89819H8.46152V6.12897H15.6154V3.89819H16.6154V6.12897H18.3846C18.8449 6.12897 19.2292 6.28314 19.5375 6.59147C19.8458 6.8998 20 7.28409 20 7.74434V20.5136C20 20.9738 19.8458 21.3581 19.5375 21.6665C19.2292 21.9748 18.8449 22.129 18.3846 22.129H5.61538ZM5.61538 21.129H18.3846C18.5385 21.129 18.6795 21.0649 18.8077 20.9367C18.9359 20.8085 19 20.6674 19 20.5136V11.7443H5V20.5136C5 20.6674 5.0641 20.8085 5.1923 20.9367C5.32052 21.0649 5.46154 21.129 5.61538 21.129ZM5 10.7443H19V7.74434C19 7.59051 18.9359 7.44949 18.8077 7.32127C18.6795 7.19307 18.5385 7.12897 18.3846 7.12897H5.61538C5.46154 7.12897 5.32052 7.19307 5.1923 7.32127C5.0641 7.44949 5 7.59051 5 7.74434V10.7443Z"
									fill="#123258"
								/>
							</svg>
							<p className="text-primary text-xs md:text-sm">
								{DateTime.fromISO(appointmentDate).setLocale(appLanguage).toFormat('LLL dd, cccc')}
							</p>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="21"
								viewBox="0 0 20 21"
								fill="none"
								className="shrink-0 max-md:h-[18px] max-md:w-[18px]">
								<path
									d="M9.99935 19.2738C14.6017 19.2738 18.3327 15.5429 18.3327 10.9405C18.3327 6.33814 14.6017 2.60718 9.99935 2.60718C5.39698 2.60718 1.66602 6.33814 1.66602 10.9405C1.66602 15.5429 5.39698 19.2738 9.99935 19.2738Z"
									stroke="#123258"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10 5.94116V10.9412L13.3333 12.6078"
									stroke="#123258"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<p className="text-primary text-xs md:text-sm">{pickupTime}</p>
						</div>
					</div>
				</div>
			)}

			<h1 className="text-primary text-xl font-bold pb-4 border-b border-[#D3E3F1]">
				{t(tKey('titles.additionalInformation'))}
			</h1>
			<textarea
				onChange={e => setAdditionalInfo(e.target.value)}
				value={additionalInfo}
				placeholder={t(tKey('titles.additionalInformation'))}
				name="additionalInformation"
				rows={3}
				className="w-full rounded font-normal py-3 bg-transparent focus:ring-0 border disabled:text-gray-500 focus:border-secondary focus-visible:outline-none border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-sm focus:outline-none text-sm"
			/>
		</div>
	)
}
